.editor-data * {
  font-family: 'DM Sans', sans-serif !important;
  background-color: inherit !important;
  margin: 0 0 1rem !important;
  text-indent: inherit !important;
  color: #000 !important;
  text-align: left !important;
  padding: 0 !important;
  font-size: 0.875rem !important;
  line-height: 160% !important;
}
.editor-data h1 * {
  font-family: 'DM Sans', sans-serif !important;
  background-color: inherit !important;
  font-size: 2.827rem !important;
  line-height: 1.5 !important;
  margin: 0 !important;
}
.editor-data h2 * {
  font-family: 'DM Sans', sans-serif !important;
  background-color: inherit !important;
  line-height: 1.5 !important;
  font-size: 1.999rem !important;
  margin: 0 !important;
}
.editor-data h3 *, .editor-data h4 * {
  font-family: 'DM Sans', sans-serif !important;
  background-color: inherit !important;
  font-size: 1.414rem !important;
  line-height: 1.414 !important;
  margin: 0 !important;
}
.editor-data h5 *, .editor-data h6 * {
  font-family: 'DM Sans', sans-serif !important;
  background-color: inherit !important;
  line-height: 1.5 !important;
  text-transform: none !important;
  font-size: 1rem !important;
  margin: 0 !important;
}
.editor-data p {
  font-size: 0.875rem !important;
  line-height: 160% !important;
  color: #000 !important;
  margin: 0 0 1rem !important;
}
.editor-data p.semi-bold {
  font-weight: 600 !important;
}
.editor-data p * {
  margin: 0 !important;
}
.editor-data strong {
  color: #000 !important;
  font-weight: 700 !important;
}
.editor-data strong * {
  margin: 0 !important;
}
.editor-data table {
  max-width: 100% !important;
  width: 100% !important;
}
.editor-data ul, .editor-data ol {
  display: block !important;
}
.editor-data ul li, .editor-data ol li {
  white-space: normal !important;
  font-size: 0.875rem !important;
  line-height: 160% !important;
  position: relative !important;
  color: #000 !important;
  text-align: left !important;
  margin-bottom: 1rem !important;
}
.editor-data ul li *, .editor-data ol li * {
  margin: 0 !important;
}
.editor-data img {
  display: block !important;
  margin-bottom: 1rem !important;
  max-width: 100% !important;
  height: auto !important;
  object-fit: contain !important;
}
.editor-data ul {
  list-style: none !important;
}
.editor-data ul li {
  padding-left: 24px !important;
}
.editor-data ul li::before {
  content: "";
  background-image: url("https://educrack.com/static/asset/svg/new/star.svg") !important;
  width: 13px !important;
  height: 14px !important;
  position: absolute !important;
  left: 0 !important;
  top: 5px !important;
  background-color: transparent !important;
  opacity: 1 !important;
  transform: none !important;
}
.editor-data ol {
  list-style: decimal !important;
  padding-left: 1rem !important;
}


.slick-slide img {
  height:  100%;
  display: block !important;
}

.slick-dots {
  bottom: 12px;
  display: flex !important;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.slick-dots li {
  margin: 0;
  display: block;
  width: auto;
  height: auto;
}

.slick-dots li.slick-active button {
  background-color: #00C385;
}

.slick-next {
  right: 12px;
}

.slick-prev, .slick-next {
  z-index: 1;
}

.slick-prev {
  left: 12px;
}

.slick-dots li button {
  width: 10px;
  border-radius: 50%;
  height: 10px;
  background-color: rgba(255,255,255,0.5);
  padding: 0;
}

.slick-dots li button::before {
  display: none;
}